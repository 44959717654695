.wrap {
  /* background: #fff; */
}

.jobs {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 3rem 2rem;
  position: relative;
}

.job:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  left: 0%;
  height: 10px;
  background: repeating-linear-gradient(
    -55deg,
    transparent,
    transparent 10px,
    #333 10px,
    #333 11px
  );
  opacity: 0.2;
}

.job {
  padding-top: 2rem;
  position: relative;
  text-decoration: none;
}

.title {
  display: inline-block;
  text-decoration: underline;
  text-decoration-color: #fb7670;
  transition: 0.2s ease-out;
}

.moreInfo {
  display: inline-block;
  padding: 0.5rem 1rem;
  background: #fb7670;
  font-family: Realtime;
  font-size: 16px;
  font-weight: bold;
  transition: 0.2s ease-out;
}

.job:hover .moreInfo {
  background: #fb767090;
}

.job:hover .title {
  text-underline-offset: 1px;
}
