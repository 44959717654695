.wrap {
	margin-bottom: 2rem;
}

.wrap:last-child {
	margin-bottom: 0;
}

.items {
	margin-left: 0;
	padding-left: 1rem;
}

.isParagraphs{
	padding-left: 0;
}

.gridded {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	grid-gap: 0 2rem;
}
