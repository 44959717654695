.bg {
	background: #81debd;
	position: relative;
	max-width: 340px;
	margin-left: auto;
	margin-right: auto;
}

.bgBlue {
	background: #0d1b24;
}

.bgCream {
	background: #fff9f9;
	background: #ffe6e6;
}

.caption {
	opacity: 0.8;
	font-family: Realtime;
	font-size: var(--fs-s);
	margin-top: 0.5rem;
	text-align: center;
}

.caption a {
	text-decoration: none;
	border-bottom: 1px solid;
}

.bg svg {
	width: 100%;
}
