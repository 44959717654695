:root {
  --fs-s: 14px;
  --fs-m: 16px;
  --fs-l: 22px;
  --fs-xl: 50px;
  --fs-xxl: 80px;
}

@media (min-width: 500px) {
  :root {
    --fs-s: 16px;
    --fs-m: 20px;
    --fs-l: 24px;
    --fs-xl: 70px;
    --fs-xxl: 90px;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
}

/* Border Box all the things */
* {
  box-sizing: border-box;
}

html {
  font-family: "Sectra", "Helvetica Neue", arial, sans-serif;
  font-size: var(--fs-m);
  line-height: 1.4;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga" 1, "kern" 1;
  overflow-wrap: break-word;
  word-wrap: break-word;
  color: #333745;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Shared vertical rhythm - csswizardry.com/2012/06/single-direction-margin-declarations */
h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
ul,
ol,
ol > li,
dl,
blockquote,
p,
address,
table,
fieldset,
figure,
pre {
  margin-top: 0;
  margin-bottom: 24px;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
hgroup:last-child,
ul:last-child,
ol:last-child,
ol > li:last-child,
dl:last-child,
blockquote:last-child,
p:last-child,
address:last-child,
table:last-child,
fieldset:last-child,
figure:last-child,
pre:last-child {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Brown;
  font-weight: bold;
  line-height: 1.1;
}

h1 {
  font-size: var(--fs-xxl);
}

h2 {
  font-size: var(--fs-l);
}

h3 {
  font-size: var(--fs-m);
}

h4 {
  font-size: var(--fs-m);
}

/* Makes images responsive by default */
img,
svg {
  display: inline-block;
  vertical-align: bottom;
  max-width: 100%;
}

/* Remove the border around images in IE */
a img {
  border: 0;
}

figure {
  padding: 0;
  margin: 0;
}

/* Prevent superscripts and subscripts from affecting line-height */
sup,
sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}

sub {
  top: 0.4em;
}

/* Form elements inherit fonts */
input,
textarea,
button {
  font: inherit;
}

a,
a:hover,
a:visited,
a:active {
  color: inherit;
}

li {
  margin-bottom: 0.5rem;
}

li:last-child {
  margin-bottom: 0;
}
