.wrap {
	font-weight: bold;
	font-size: 12px;
	line-height: 1.1;
	text-transform: uppercase;
	font-family: helvetica;
	color: #001e4d;
	padding: 1rem;
	/* margin-left: -2%;
	margin-right: -2%; */
}

.text {
	max-width: 220px;
	border-bottom: 1px solid;
	padding-bottom: 5px;
	display: grid;
	margin-left: auto;
	margin-right: auto;
	font-size: 10px;
	grid-template-columns: 1fr min-content;
	align-items: center;
	transform: translateY(10%);
	opacity: 0;
	transition: 0.4s ease-out;
	will-change: all;
}

.number {
	font-size: 24px;
	padding-left: 5px;
}

.dots {
	display: grid;
	grid-template-columns: repeat(23, 1fr);
	grid-gap: 2px 2px;
	align-items: center;
}

.dot {
	display: block;
	padding-bottom: calc(100% - 2px);
	border: 1px solid #ffc8c6;
	position: relative;
	border-radius: 50%;
	color: transparent;
}

.dotInner {
	content: '';
	position: absolute;
	top: -1px;
	left: -1px;
	right: -1px;
	bottom: -1px;
	background: currentColor;
	border-radius: 50%;
	transform: scale(0.1);
	opacity: 0;
	transition: 0.2s ease-out;
	will-change: all;
}

.inView .text {
	opacity: 1;
	transform: translateY(0);
}

.inView .dot {
	/* opacity: 1; */
	/* transform: translateX(0); */
}

.inView .dotInner {
	opacity: 1;
	transform: scale(1);
}
