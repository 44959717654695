.container {
	max-width: 768px;
	margin-left: auto;
	margin-right: auto;
	padding: 2rem 1rem;
}

@supports (padding: max(0px)) {
	.container {
		padding-left: max(1rem, env(safe-area-inset-left));
		padding-right: max(1rem, env(safe-area-inset-right));
	}
}
