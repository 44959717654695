.wrap {
	max-width: 908px;
	margin-left: auto;
	margin-right: auto;
	padding: 4rem 1rem 3rem;
	position: relative;
}

@supports (padding: max(0px)) {
	.wrap {
		padding-left: max(1rem, env(safe-area-inset-left));
		padding-right: max(1rem, env(safe-area-inset-right));
	}
}

.grid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 2rem 1rem;
}

.grid:before {
	content: '';
	position: absolute;
	top: 0;
	width: 60%;
	left: 20%;
	height: 0.5rem;
	background: repeating-linear-gradient(
		-55deg,
		transparent,
		transparent 10px,
		#333 10px,
		#333 11px
	);
	opacity: 0.2;
}

.hideFlourish:before {
	display: none;
}

.title,
.offset,
.after,
.column {
	grid-column: span 12;
	margin-bottom: 0;
}

.full .column {
	grid-column: span 12;
}

@media (min-width: 650px) {
	.grid {
		grid-gap: 1rem 2rem;
	}

	.title {
		grid-column: 4 / span 8;
	}

	.after,
	.offset {
		grid-column: span 3;
	}

	.after {
		grid-column: span 4;
	}

	.column {
		grid-column: span 9;
	}

	.isEqual .after,
	.isEqual .offset,
	.isEqual .column {
		grid-column: span 5;
	}

	.isEqual .column {
		grid-column: span 7;
	}

	.isCentered .column {
		grid-column: 3 / span 8;
	}
}
