.main {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.inner {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	flex: 1 0 auto;
}
