@font-face {
  font-family: "Realtime";
  src: url("assets/30AC10_2_0.woff2") format("woff2");
}

@font-face {
  font-family: "Brown";
  font-weight: bold;
  src: url("assets/lineto-brown-bold.woff") format("woff");
}

@font-face {
  font-family: "Brown";
  src: url("assets/lineto-brown-bold.woff") format("woff");
}

@font-face {
  font-family: "Sectra";
  src: url("assets/GT-Sectra-Book.woff") format("woff");
}
