.apply {
	background: #a6cfe2;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding-top: 1rem;
	padding-bottom: 1rem;
	font-size: var(--fs-l);
}

.title {
	font-size: var(--fs-xl);
}
