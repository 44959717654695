.root {
	text-align: center;
	padding: 1.5rem 1rem;
	background: #333745;
	color: #fafafa;
}

.copyright {
	text-transform: uppercase;
	font-family: Realtime, monospace;
}
