.wrap {
}

.wrap svg {
	overflow: visible;
}

.heartWrap {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	transition: 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.25);
	pointer-events: none;
}

.inView .heartWrap {
	opacity: 1;
}

.heart {
	pointer-events: all;
}
