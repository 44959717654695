.wrap {
	padding: 1rem 1rem 0;
}

.text {
	padding-bottom: 5px;
	font-size: 12px;
	transform: translateZ(0) translateY(5px);
	opacity: 0;
	transition: 0.4s ease-out;
	color: #fff !important;
	font-family: Helvetica, Arial;
	text-align: center;
	font-weight: bold;
}

.squares {
	text-align: center;
	transform: translateY(-8%) translateX(-3%) rotate(45deg);
	margin-left: 8%;
	margin-right: 7%;
	margin-top: -5%;
}

.square {
	display: inline-block;
	margin: 1%;
	vertical-align: bottom;
	transform: scale(0) rotate(-45deg);
	opacity: 0;
	transition: 0.8s ease-out;
	will-change: all;
}

.square:nth-child(1) {
	margin-left: 50%;
}

.square:nth-child(n + 10) {
	vertical-align: top;
}

.inner {
	display: block;
	height: 0;
	padding-bottom: 100%;
	background: #1dc7ef;
}

.inView .square {
	transform: scale(1) rotate(0);
	opacity: 1;
}

.inView .text {
	transform: translateZ(0) translateY(0%);
	opacity: 1;
}
