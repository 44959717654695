.intro {
  text-align: center;
  min-height: 45vh;

  max-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #333;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
  z-index: 1;
}

.intro:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(assets/bg.svg) center center;
  background-size: 1648px 507px;
  z-index: -1;
  opacity: 0.5;
}

.text {
  font-size: var(--fs-xxl);
  color: #d0f183;
}
